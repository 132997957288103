@import 'scss-imports/splitview';

/* Universal Overrides for Egret */

.hidden {
  display: none;
}

.invisible {
  opacity: 0;
}

/* Navigation shorter and fixed to Top */
div.mat-toolbar {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  min-height: 64px;
  padding: 0 16px;
  width: 100%;
}

.xs .rightside-content-hold {
  padding: 0 !important;

  @media (max-width: $breakpoint-tablet) {
    padding-bottom: 96px !important;
  }
}

/* Tabbed navigation is fixed too */
.tab-header-fixed .mat-mdc-tab-header {
  background: #efefef;
  flex-shrink: 0;
  overflow: hidden;
  position: fixed !important;
  top: 104px;
  width: 100%;
  z-index: 2;
}
/*--- Data table ---*/
.datatable-checkbox input[type='checkbox'] {
  margin-left: -3px;
  margin-top: -10px;
}

.mat-mdc-tab-body-wrapper {
  padding: 16px;
}

/* Cards & Tables */
.mat-mdc-card-table {
  padding: 0 !important;
}

.mat-card-title-text {
  font-size: 24px;
  margin: 16px;
}

/* mat-sidenav */
mat-sidenav-content.fn-maincontent {
  transform: translate3d(0, 0, 0);
}

body.collapsed-menu mat-sidenav.mat-sidenav-closed mat-sidenav-content.fn-maincontent {
  margin-left: 48px !important;
  margin-right: 0 !important;
  transform: translate3d(0, 0, 0);
}

body.collapsed-menu mat-sidenav-container.mat-drawer-opened mat-sidenav-content.fn-maincontent {
  margin-left: 48px !important;
  margin-right: 0 !important;
  transform: translate3d(0, 0, 0);
}

.mat-sidenav.mat-sidenav-opened,
.mat-sidenav.mat-sidenav-opening {
  transform: translate3d(0, 0, 0);
}

/* side menu*/
.mat-nav-list .mat-mdc-list-item {
  display: block;
  height: auto !important;
}

.cdk-drop-list-dragging {
  cursor: grabbing;
}
